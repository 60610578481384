<template>
  <NextStepsItem :icon="IconContact">
    <FormattedMessage :definition="translations.gotQuestionslabel">
      <template #link>
        <RevLink target="_blank" :to="i18n(translations.linkToFAQ)">
          {{ i18n(translations.linkLabel) }}
        </RevLink>
      </template>
    </FormattedMessage>
  </NextStepsItem>
</template>

<script setup lang="ts">
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { IconContact } from '@ds/icons/IconContact'

import NextStepsItem from '../../../components/NextStepsItem/NextStepsItem.vue'

import translations from './GotQuestionsItem.translations'

const i18n = useI18n()
</script>
