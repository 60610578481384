export default {
  title: {
    id: 'visible_by_verizon_card_title',
    defaultMessage: 'Check your email for your exclusive Visible promo code.',
  },
  content: {
    id: 'visible_by_verizon_card_content',
    defaultMessage:
      'Back Market customers can get exclusive pricing on the Visible+ plan, just $30/month for no-contract unlimited data with Verizon 5G.',
  },
  buttonContent: {
    id: 'visible_by_verizon_card_button_content',
    defaultMessage: 'See plan details',
  },
  infoBlockTitle: {
    id: 'visible_by_verizon_card_info_block_title',
    defaultMessage:
      'Already have a plan? Give this code to a friend or use it with another device.',
  },
  logo: {
    id: 'visible_by_verizon_offer_partnered_with_back_market',
    defaultMessage: 'Visible partnered with Back Market',
  },
}
