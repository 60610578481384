<template>
  <NextStepsItem :icon="IconCheckInCircle">
    {{ i18n(translations.mobilePlanConfirmation) }}
  </NextStepsItem>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import NextStepsItem from '../../../components/NextStepsItem/NextStepsItem.vue'

import translations from './BouyguesMobilePlanConfirmationItem.translations'

const i18n = useI18n()
</script>
