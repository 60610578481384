<template>
  <RevCard>
    <div
      class="flex h-[115px] items-center justify-between rounded-t-inherit bg-[#1800FF] px-24 md:h-[76px]"
    >
      <div class="text-static-light-hi heading-2">
        {{ i18n(translations.title) }}
      </div>

      <RevIllustration
        :alt="i18n(translations.logo)"
        class="hidden md:block"
        :height="35"
        src="/img/visible-by-verizon/visible-by-verizon-logo-payment-desktop.svg"
        :width="190"
      />
    </div>
    <div class="flex flex-col gap-16 p-24 md:grid md:grid-cols-2 md:gap-48">
      <div class="body-1 flex flex-col space-y-8">
        <p>{{ i18n(translations.content) }}</p>
        <div>
          <RevButtonTiny
            full-width="never"
            :tracking="{
              zone: 'confirmation',
              name: 'see_plan_details_visible',
            }"
            variant="secondary"
            @click="openModal(MODAL_NAMES.VISIBLE_BY_VERIZON)"
          >
            {{ i18n(translations.buttonContent) }}
          </RevButtonTiny>
        </div>
      </div>
      <div>
        <RevInfoBlock
          :icon="IconLightbulb"
          :title="i18n(translations.infoBlockTitle)"
          variant="warning"
        />
      </div>
    </div>
  </RevCard>

  <VisibleByVerizonDrawer />
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { openModal } from '@ds/components/ModalBase'
import { IconLightbulb } from '@ds/icons/IconLightbulb'

import VisibleByVerizonDrawer from '~/scopes/product/components/CustomizationFunnel/components/VisibleByVerizonDrawer/VisibleByVerizonDrawer.vue'
import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './VisibleByVerizonCard.translations'

const i18n = useI18n()
</script>
