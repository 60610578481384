export default {
  followOrder: {
    id: 'new_payment_result_success_follow_order',
    defaultMessage: 'Track your order',
  },
  install: {
    id: 'new_payment_result_success_install',
    defaultMessage: 'Install our app to track delivery and stay in touch.',
  },
  merchants: {
    id: 'payment_result_success_merchants',
    defaultMessage: `{merchantCount, plural,
      one {merchant}
      other {merchants}
    }`,
  },
}
