<template>
  <NextStepsItem :icon="IconSim">
    {{ i18n(translations.bouyguesSIMCard) }}
  </NextStepsItem>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconSim } from '@ds/icons/IconSim'

import NextStepsItem from '../../../components/NextStepsItem/NextStepsItem.vue'

import translations from './BouyguesSIMCardItem.translations'

const i18n = useI18n()
</script>
