<template>
  <NextStepsItem :icon="IconPackage">
    <FormattedMessage :definition="translations.step3">
      <template #account>
        <RevLink
          target="_blank"
          :to="resolveLocalizedRoute({ name: DASHBOARD.SELF })"
        >
          {{ i18n(translations.account) }}
        </RevLink>
      </template>
    </FormattedMessage>
  </NextStepsItem>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { IconPackage } from '@ds/icons/IconPackage'

import { DASHBOARD } from '~/scopes/dashboard/routes'

import NextStepsItem from '../../NextStepsItem/NextStepsItem.vue'

import translations from './TrackYourPackageItem.translations'

const resolveLocalizedRoute = useRouteLocationWithLocale()
const i18n = useI18n()
</script>
