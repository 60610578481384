<template>
  <div>
    <PaymentSuccessHeader
      class="bg-static-success-low"
      illustrationPath="/img/payment/paymentResultThankYou.svg"
      :paragraph="headerDescriptionDefinition"
      :payment="payment"
      :subtitle="i18n(translations.subtitle)"
      :title="i18n(translations.title)"
    />

    <RevContainer class="mt-40">
      <span class="heading-3">
        {{ i18n(translations.steps) }}
      </span>
      <div class="grid-standard mt-24 gap-16 md:mt-36">
        <div v-if="props.payment?.client?.email" class="md:col-span-4 md:block">
          <OrderDetailsItem
            :client-email="props.payment.client.email"
            variant="icon-top"
          />
        </div>
        <div class="md:col-span-4 md:block">
          <SearchInSpamsItem variant="icon-top" />
        </div>
        <div class="md:col-span-4 md:block">
          <TrackYourPackageItem variant="icon-top" />
        </div>
      </div>
    </RevContainer>

    <RevContainer v-if="hasVisibleByVerizon" class="mt-56">
      <VisibleByVerizonCard />
    </RevContainer>

    <RevContainer v-if="!isEmpty(payment.sourcingOrders)">
      <RevDivider class="my-36 md:my-56" />
    </RevContainer>

    <SourcingOrdersNextSteps :sourcing-orders="payment.sourcingOrders" />

    <RevContainer>
      <RevDivider class="my-36 md:my-56" />
    </RevContainer>

    <OrderSection :payment="payment" />

    <RevContainer>
      <RevDivider class="my-36 md:my-56" />
    </RevContainer>

    <PaymentSuccessFooter :payment="payment" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { PaymentResult } from '@backmarket/http-api/src/api-specs-payment/payment'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevContainer } from '@ds/components/Container'
import { RevDivider } from '@ds/components/Divider'

import OrderSection from '../components/OrderSection/OrderSection.vue'
import PaymentSuccessFooter from '../components/PaymentSuccessFooter/PaymentSuccessFooter.vue'
import PaymentSuccessHeader from '../components/PaymentSuccessHeader/PaymentSuccessHeader.vue'
import SourcingOrdersNextSteps from '../components/SourcingOrdersNextSteps/SourcingOrdersNextSteps.vue'
import VisibleByVerizonCard from '../components/VisibleByVerizonCard/VisibleByVerizonCard.vue'
import OrderDetailsItem from '../components/next-steps-items/OrderDetailsItem/OrderDetailsItem.vue'
import SearchInSpamsItem from '../components/next-steps-items/SearchInSpamsItem/SearchInSpamsItem.vue'
import TrackYourPackageItem from '../components/next-steps-items/TrackYourPackageItem/TrackYourPackageItem.vue'

import translations from './PaymentSuccess.translations'

const i18n = useI18n()

const props = defineProps<{
  payment: PaymentResult
}>()

const headerDescriptionDefinition = computed(() =>
  props.payment.voucher
    ? translations.voucherDescription
    : translations.description,
)

const hasVisibleByVerizon = props.payment.orders.some((order) =>
  order?.orderlines.some(
    (orderline) => orderline.partnerPromoCode?.partnerName === 'VISIBLE',
  ),
)
</script>
