<template>
  <div v-for="source in sourcingOrders" :key="source.id">
    <RevContainer data-test="sourcing">
      <div class="space-y-24">
        <h3 class="heading-3">
          {{ i18n(translations.sourcingOrderTitle, { title: source.title }) }}
        </h3>

        <div v-if="source.wording" class="grid gap-16 md:mt-36 md:grid-cols-3">
          <NextStepsItem
            v-if="source.wording.shipping"
            :icon="IconBill"
            variant="icon-top"
          >
            {{ source.wording.shipping }}
          </NextStepsItem>
          <NextStepsItem
            v-if="source.wording.information"
            :icon="IconAvatar"
            variant="icon-top"
          >
            {{ source.wording.information }}
          </NextStepsItem>
          <NextStepsItem
            v-if="source.wording.payment"
            :icon="IconCreditCard"
            variant="icon-top"
          >
            {{ source.wording.payment }}
          </NextStepsItem>
        </div>
      </div>
    </RevContainer>
  </div>
</template>

<script setup lang="ts">
import type { PaymentResult } from '@backmarket/http-api/src/api-specs-payment/payment'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevContainer } from '@ds/components/Container'
import { IconAvatar } from '@ds/icons/IconAvatar'
import { IconBill } from '@ds/icons/IconBill'
import { IconCreditCard } from '@ds/icons/IconCreditCard'

import NextStepsItem from '../NextStepsItem/NextStepsItem.vue'

import translations from './SourcingOrdersNextSteps.translations'

const i18n = useI18n()

defineProps<{
  sourcingOrders: PaymentResult['sourcingOrders']
}>()
</script>
