<template>
  <RevContainer class="pb-24 pt-40 md:py-72">
    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-56">
      <div class="text-static-default-hi flex max-w-[600px] flex-col">
        <RevIllustration
          :alt="title"
          class="block h-[140px] w-[137px] self-center md:mb-18 lg:self-start"
          data-qa="payment-success-gif"
          :height="140"
          :src="illustrationPath"
          :width="137"
        />
        <h2 class="body-1">
          {{ subtitle }}
        </h2>
        <span class="heading-1 mb-18">
          {{ title }}
        </span>
        <ResultVoucherCard
          v-if="payment.voucher"
          :payment-method="payment.method as PaymentMethod"
          :voucher="payment.voucher"
        />
        <div class="body-1 mb-16">
          <FormattedMessage :definition="paragraph">
            <template #link
              ><RevLink target="_blank" :to="firstMerchant">{{
                i18n(translations.merchants, {
                  merchantCount: merchantsNames.length,
                })
              }}</RevLink>
            </template>
            <template #merchants>{{ merchantsNames.join(', ') }}</template>
          </FormattedMessage>
        </div>
      </div>

      <div>
        <slot name="aside">
          <MobileAppCard v-if="features.mobileApp.available" />
        </slot>
      </div>
    </div>
  </RevContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type {
  PaymentMethod,
  PaymentResult,
} from '@backmarket/http-api/src/api-specs-payment/payment'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import { type Features } from '~/features'

import translations from './PaymentSuccessHeader.translations'
import MobileAppCard from './components/MobileAppCard/MobileAppCard.vue'
import ResultVoucherCard from './components/ResultVoucherCard/ResultVoucherCard.vue'

const { features } = useMarketplace<Features>()
const i18n = useI18n()

const props = defineProps<{
  title: string
  subtitle: string
  paragraph: I18nDefinition<string>
  illustrationPath: string
  payment: PaymentResult
}>()

const merchantsNames = computed(() => {
  if (!props.payment.orders) return []

  return props.payment.orders.map((order) => order.merchant.name)
})

const firstMerchant = computed(() => {
  const merchants = props.payment.orders.map((order) => order.merchant)

  return merchants.length > 0 ? merchants[0].link : {}
})
</script>
