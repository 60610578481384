<template>
  <RevCard class="flex items-start p-24 md:p-32">
    <RevIllustration
      :alt="i18n(translations.followOrder)"
      class="hidden min-h-[207px] min-w-[100px] max-w-[100px] md:mr-32 md:block"
      :height="207"
      src="/img/payment/phoneImage.png"
      :width="100"
    />
    <div>
      <span class="body-1-bold mb-4">
        {{ i18n(translations.followOrder) }}
      </span>
      <p class="body-1 mb-16">
        {{ i18n(translations.install) }}
      </p>

      <AppStoreButtons
        campaign="payment_confirmation"
        class="flex items-center justify-stretch"
      />
      <slot />
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './MobileAppCard.translations'

const i18n = useI18n()
</script>
