<template>
  <RevContainer>
    <h3 class="heading-3">
      {{ orderContainerTitle }}
    </h3>

    <div class="grid-standard mt-24 gap-16 md:mt-32">
      <SummaryCard
        :billing-address="payment.client.billingAddress"
        class="md:col-span-9 lg:col-span-3"
        data-cs-mask
        :date="payment.date"
        :is-post-purchase="isPostPurchase"
        :payment-id="payment.paymentId"
        :payment-method="payment.method"
        :shipping-address="payment.client.shippingAddress"
      />
      <div class="md:col-span-9">
        <div v-if="!isPostPurchase">
          <div
            v-for="order in payment.orders"
            :key="order.id"
            class="mb-16 last-of-type:mb-24"
          >
            <div
              v-for="orderline in order.orderlines"
              :key="orderline.id"
              class="space-y-16"
            >
              <OrderlineSummaryCard
                data-qa="payment-order-container"
                :discount-token="payment.discountDetails?.token"
                :merchant="order.merchant"
                :orderline="orderline"
                :subsidy="orderline.mobilePlan?.offer?.subsidy"
              />
              <BouyguesSummaryCard
                v-if="orderline.mobilePlan"
                data-qa="bouygues-summary-container"
                :offer="orderline.mobilePlan.offer"
              />
            </div>
          </div>
        </div>
        <InsuranceSummaryCard
          v-if="isPostPurchase && payment.postPurchase"
          class="md:col-span-9"
          data-qa="payment-order-container"
          :offer="payment.postPurchase?.insuranceOffer"
          :policy="payment.postPurchase?.insurancePolicy"
          :title="payment.postPurchase?.productTitle"
        />

        <div v-if="displayServiceFee" class="body-2 mt-24 flex justify-between">
          <ServiceFeeModal />
          <div>
            {{ i18n.price(payment.serviceFee) }}
          </div>
        </div>

        <div class="body-1-bold mt-10 flex justify-between">
          <div>{{ i18n(translations.total) }}</div>
          <div data-qa="payment-total-price">
            {{ totalPrice }}
          </div>
        </div>
      </div>
    </div>
  </RevContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { PaymentResult } from '@backmarket/http-api/src/api-specs-payment/payment'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevContainer } from '@ds/components/Container'

import ServiceFeeModal from '~/scopes/checkout/components/ServiceFeeModal.vue'

import BouyguesSummaryCard from '../../BouyguesPaymentSuccess/components/BouyguesSummaryCard/BouyguesSummaryCard.vue'

import translations from './OrderSection.translations'
import InsuranceSummaryCard from './components/InsuranceSummaryCard/InsuranceSummaryCard.vue'
import OrderlineSummaryCard from './components/OrderlineSummaryCard/OrderlineSummaryCard.vue'
import SummaryCard from './components/SummaryCard/SummaryCard.vue'

const props = defineProps<{
  payment: PaymentResult
}>()

const i18n = useI18n()

const isPostPurchase = computed(() => !isEmpty(props.payment.postPurchase))

const orderContainerTitle = computed(() =>
  isPostPurchase.value
    ? i18n(translations.yourCoverageTitle)
    : i18n(translations.order),
)

const totalPrice = computed(() =>
  isPostPurchase.value
    ? i18n.price(props.payment.postPurchase?.insuranceOffer.price ?? '')
    : i18n.price(props.payment.amountAfterDiscount),
)

const displayServiceFee = computed(
  () => props.payment.serviceFee && !isPostPurchase.value,
)
</script>
