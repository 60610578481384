<template>
  <NextStepsItem :icon="IconSearch">
    {{ i18n(translations.step2) }}
  </NextStepsItem>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconSearch } from '@ds/icons/IconSearch'

import NextStepsItem from '../../NextStepsItem/NextStepsItem.vue'

import translations from './SearchInSpamsItem.translations'

const i18n = useI18n()
</script>
