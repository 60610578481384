import { useRuntimeConfig } from '#imports'

import type {
  Order,
  Orderline,
  PaymentMethod,
  PaymentResult,
} from '@backmarket/http-api/src/api-specs-payment/payment'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { useAuthStore } from '@backmarket/nuxt-module-oauth/useAuthStore'
import { useUserStore } from '@backmarket/nuxt-module-oauth/useUserStore'
import { usePaymentAdvertisement } from '@backmarket/nuxt-module-payment/advertising/composables/usePaymentAdvertisement'
import { PaymentLogTypes } from '@backmarket/nuxt-module-payment/form'
import type { EventData } from '@backmarket/nuxt-module-tracking'
import { formatPrice } from '@backmarket/nuxt-module-tracking/formatPrice'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { omit } from '@backmarket/utils/object/omit'

const CUSTOMER_TYPE = {
  EXISTING: 'existing',
  PROSPECT: 'prospect',
}
const PAYMENT_SENSITIVE_DATA = ['client']
const PRODUCT_DEAL_TYPE = {
  NORMAL: 'normal',
}

const formatProduct = (
  order: Order,
  orderline: Orderline,
  advertisedMethodsId: string,
) => {
  let productId = `${orderline.product?.id}`
  let productPrice
  let insurance

  try {
    const match = (orderline?.product?.slug ?? []).match(/(\d+)\.html/)
    productId = match ? match[1] : ''
    productPrice =
      parseFloat(orderline.price.amount ?? 0) / (orderline.quantity ?? 1)
    insurance =
      (orderline?.coverage?.insurancePolicies ?? []).find(
        (offer) => offer.orderlineId === orderline.id,
      ) || {}
  } catch {
    // fails silently
  }

  return {
    brand: orderline.product?.brand,
    category: orderline.product?.category,
    color: orderline.product?.color,
    dealType: PRODUCT_DEAL_TYPE.NORMAL,
    specialOfferType: orderline.product?.offerType ?? 0,
    id: productId,
    listingId: orderline.product?.listingId,
    merchantId: order.merchant?.id,
    model: orderline.product?.model,
    name: orderline.product?.title,
    price: productPrice,
    currency: orderline?.price.currency,
    quantity: orderline?.quantity,
    shipper: [
      orderline?.shipping?.shipperId,
      orderline?.shipping?.deliveryDelay,
      formatPrice(orderline?.priceShipping),
    ].join(' - '),
    shippingDelay: orderline?.shipping?.deliveryDelay,
    shippingPrice: orderline?.priceShipping.amount,
    splitPayment: advertisedMethodsId,
    variant: orderline.product?.gradeId,
    warrantyDuration:
      (orderline?.coverage?.merchantWarranty?.duration ?? 0) +
      (orderline?.coverage?.backmarketWarranty?.duration ?? 0),
    insuranceTitle: insurance?.offerTitle,
    insurancePrice: insurance?.price?.amount,
    insurancePaymentType: insurance?.isMonthly ? 'monthly' : 'upfront' ?? '',
    searchRelatedQuery: orderline?.searchRelatedQuery ?? {},
    uuid: orderline.product?.akeneoId,
    mobilePlanOfferSelected: orderline.mobilePlan?.offer?.name ?? '',
  }
}

const paymentTrackingData = ({
  payment,
  lastOrderDate,
  numberOfOrders,
  transactionDate,
}: {
  payment: PaymentResult
  lastOrderDate: Date | null
  numberOfOrders: number
  transactionDate: string
}) => {
  const newCommission = (
    (payment.orders.reduce(
      (acc, order) =>
        acc +
        order.orderlines.reduce(
          (accOrderline, orderline) =>
            accOrderline + parseFloat(orderline?.price?.amount ?? 0),
          0.0,
        ),
      0.0,
    ) +
      parseFloat(payment.priceShipping.amount)) *
    0.1
  ).toFixed(2)

  return {
    code_parrain:
      ((payment.discountDetails?.isGodfather ?? false) &&
        payment.discountDetails?.token) ??
      '',
    revenue: payment.amount.amount,
    discount: payment.discountDetails?.token ?? '',
    commission: payment.commission.amount,
    '10Pct_FEE': `${newCommission}`,
    orderId: payment.groupOrderId,
    paymentMethod: payment.method.bmCode,
    shipping: payment.priceShipping.amount,
    swap_details: payment?.sourcingOrders[0],
    swap: !isEmpty(payment.sourcingOrders),
    totalQuantity: payment.orders.reduce(
      (acc, order) =>
        acc +
        order.orderlines.reduce(
          (accOrderline, orderline) => accOrderline + orderline.quantity,
          0,
        ),
      0,
    ),
    transactionDate,
    transactionId: payment.paymentId,
    customerType:
      lastOrderDate !== null && numberOfOrders > 0
        ? CUSTOMER_TYPE.EXISTING
        : CUSTOMER_TYPE.PROSPECT,
    monthsSinceLastPurchase: payment.client?.monthsSinceLastPurchase,
  }
}

const productsTrackingData = (
  payment: PaymentResult,
  advertisedMethodsId: string,
) => {
  return payment.orders
    .map((order) =>
      order.orderlines.map((orderline) =>
        formatProduct(order, orderline, advertisedMethodsId),
      ),
    )
    .flat(2)
}

const productsSearchTrackingData = (
  payment: PaymentResult,
  advertisedMethodsId: string,
) => {
  return productsTrackingData(payment, advertisedMethodsId).filter(
    (product) => !isEmpty(product.searchRelatedQuery),
  )
}

const postPurchaseTrackingData = (payment: PaymentResult) => {
  return {
    orderId: payment.groupOrderId ?? '',
    productName: payment.postPurchase?.productTitle ?? '',
    productOrderlineId: payment.postPurchase?.insurancePolicy.orderlineId ?? '',
    productInsuranceTitle: payment.postPurchase?.insuranceOffer.title ?? '',
    productInsurancePrice:
      payment.postPurchase?.insurancePolicy.price.amount ?? '',
  }
}

export const usePaymentResultEventTracker = (payment: PaymentResult) => {
  const { userId } = useAuthStore()
  const i18n = useI18n()
  const {
    public: { FF_NOODLE_PAYMENT_EVENTS_ENABLED },
  } = useRuntimeConfig()
  const {
    user: { lastOrderDate, numberOfOrders },
  } = useUserStore()
  const visitorId = useVisitorId()

  const paymentAdvertisementRef = usePaymentAdvertisement('product', [
    payment.method as PaymentMethod,
  ])

  const {
    trackPaymentResultFailure,
    trackPaymentResultFailureEvent,
    trackPaymentResultSuccess,
    trackPaymentResultSuccessEvent,
    trackPostPurchasePayment,
    trackSearchAnalytics,
  } = useTracking()

  const advertisedMethodsId = paymentAdvertisementRef.value.isAdvertised
    ? paymentAdvertisementRef.value.methodName
    : ''

  const trackBuilder = (type: PaymentLogTypes) => () => {
    const isSuccess = payment.success
    const payload = {
      type,
      payment: {
        ...omit(payment, PAYMENT_SENSITIVE_DATA),
        required: payment?.amountAfterDiscount?.amount !== '0.00',
        swap: !isEmpty(payment?.sourcingOrders),
        ...(isEmpty(payment?.date)
          ? {}
          : {
              // Age in seconds. This can be useful to exclude logs from users
              // reloading the page (ex: reopening their browser, etc).
              age: Math.round(
                (Date.now() - new Date(payment.date).getTime()) / 1000,
              ),
            }),
      },
      clientId: userId,
    }

    if (FF_NOODLE_PAYMENT_EVENTS_ENABLED) {
      const track = isSuccess
        ? trackPaymentResultSuccessEvent
        : trackPaymentResultFailureEvent
      track(payload as EventData)
    }

    if (!payment) {
      return
    }

    if (payment.success) {
      if (!isEmpty(payment.postPurchase)) {
        trackPostPurchasePayment(postPurchaseTrackingData(payment))
      } else {
        trackPaymentResultSuccess({
          payment: paymentTrackingData({
            lastOrderDate: lastOrderDate as Date,
            numberOfOrders,
            payment,
            transactionDate: i18n.date(new Date(payment.date)),
          }),
          products: productsTrackingData(
            payment,
            advertisedMethodsId,
          ) as EventData[],
        })
      }

      productsSearchTrackingData(payment, advertisedMethodsId).forEach(
        (orderline) => {
          trackSearchAnalytics({
            type: 'conversion',
            objectId: orderline.searchRelatedQuery.objectID ?? '',
            searchQueryID: orderline.searchRelatedQuery.id ?? '',
            tokenId: visitorId,
            index: orderline.searchRelatedQuery.index ?? '',
            position: '0',
          })
        },
      )
    } else {
      trackPaymentResultFailure({
        paymentMethod: payment.method?.bmCode,
        errorCode: payment.errorCode,
        errorReason: payment.errorMessage,
      })
    }
  }

  const track = (type: PaymentLogTypes) => trackBuilder(type)

  track.paymentSuccess = trackBuilder(PaymentLogTypes.RESULT_SUCCESS)

  track.paymentFailure = trackBuilder(PaymentLogTypes.RESULT_FAILURE)

  return track
}
