<template>
  <PaymentSuccessHeader
    class="bg-static-info-mid"
    illustrationPath="/img/bouygues/bouyguesPaymentSuccess.svg"
    :paragraph="translations.headerParagraph"
    :payment="payment"
    :subtitle="i18n(translations.subtitle)"
    :title="i18n(translations.title)"
  >
    <template #aside>
      <div class="space-y-16">
        <MobileAppCard v-if="features.mobileApp.available">
          <div class="mt-16 hidden space-y-16 md:flex md:flex-col">
            <RevDivider class="block" />
            <RevLink class="text-left" :to="bouyguesSupportLink">
              {{ i18n(translations.asideLink) }}
            </RevLink>
            <div>
              {{ i18n(translations.asideParagraph) }}
            </div>
          </div>
        </MobileAppCard>

        <RevCard class="space-y-16 p-24 md:hidden">
          <div class="space-y-8">
            <BouyguesLogo />

            <div class="body-1-bold">
              {{ i18n(translations.headerBouyguesCardTitle) }}
            </div>
            <div class="body-1">
              {{ i18n(translations.headerBouyguesCardContent) }}
            </div>
          </div>
          <RevButton
            fullWidth="always"
            :to="bouyguesSupportLink"
            variant="secondary"
          >
            {{ i18n(translations.headerBouyguesCardButton) }}
          </RevButton>
        </RevCard>
      </div>
    </template>
  </PaymentSuccessHeader>

  <RevContainer class="mt-40">
    <div class="space-y-24">
      <h3 class="heading-3">
        {{ i18n(translations.steps) }}
      </h3>

      <div
        class="grid gap-x-56 gap-y-16 md:mt-36 md:grid-flow-col md:grid-cols-2 md:grid-rows-3"
      >
        <OrderDetailsItem
          v-if="props.payment?.client?.email"
          :client-email="props.payment.client.email"
          variant="icon-left"
        />
        <SearchInSpamsItem variant="icon-left" />
        <BouyguesMobilePlanConfirmationItem variant="icon-left" />
        <BouyguesSIMCardItem variant="icon-left" />
        <TrackYourPackageItem variant="icon-left" />
        <GotQuestionsItem variant="icon-left" />
      </div>
    </div>
  </RevContainer>

  <RevContainer v-if="!isEmpty(payment.sourcingOrders)">
    <RevDivider class="my-36 md:my-56" />
  </RevContainer>

  <SourcingOrdersNextSteps :sourcing-orders="payment.sourcingOrders" />

  <RevContainer>
    <RevDivider class="my-36 md:my-56" />
  </RevContainer>

  <OrderSection :payment="payment" />

  <RevContainer>
    <RevDivider class="my-36 md:my-56" />
  </RevContainer>

  <PaymentSuccessFooter :payment="payment" />
</template>

<script setup lang="ts">
import type { PaymentResult } from '@backmarket/http-api/src/api-specs-payment/payment'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevContainer } from '@ds/components/Container'
import { RevDivider } from '@ds/components/Divider'
import { RevLink } from '@ds/components/Link'

import BouyguesLogo from '~/components/BouyguesLogo/BouyguesLogo.vue'
import { type Features } from '~/features'

import OrderSection from '../components/OrderSection/OrderSection.vue'
import PaymentSuccessFooter from '../components/PaymentSuccessFooter/PaymentSuccessFooter.vue'
import PaymentSuccessHeader from '../components/PaymentSuccessHeader/PaymentSuccessHeader.vue'
import MobileAppCard from '../components/PaymentSuccessHeader/components/MobileAppCard/MobileAppCard.vue'
import SourcingOrdersNextSteps from '../components/SourcingOrdersNextSteps/SourcingOrdersNextSteps.vue'
import OrderDetailsItem from '../components/next-steps-items/OrderDetailsItem/OrderDetailsItem.vue'
import SearchInSpamsItem from '../components/next-steps-items/SearchInSpamsItem/SearchInSpamsItem.vue'
import TrackYourPackageItem from '../components/next-steps-items/TrackYourPackageItem/TrackYourPackageItem.vue'

import translations from './BouyguesPaymentSuccess.translations'
import BouyguesMobilePlanConfirmationItem from './components/BouyguesMobilePlanConfirmationItem/BouyguesMobilePlanConfirmationItem.vue'
import BouyguesSIMCardItem from './components/BouyguesSIMCardItem/BouyguesSIMCardItem.vue'
import GotQuestionsItem from './components/GotQuestionsItem/GotQuestionsItem.vue'

const { features } = useMarketplace<Features>()
const i18n = useI18n()
const props = defineProps<{
  payment: PaymentResult
}>()

const bouyguesSupportLink = 'https://www.bouyguestelecom.fr/mon-compte'
</script>
