<template>
  <div
    v-if="displayBadge"
    :id="BADGE_ID"
    class="fixed bottom-0 right-0 mb-20 mr-20"
    data-test="trusted-shops-badge"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { BADGE_ID, DEFAULT_WIDGET_CONFIG } from './TrustedShopsBadge.config'
import { useTrustedShopsLibrary } from './useTrustedShopsLibrary'

const logger = useLogger()

const displayBadge = ref(false)

try {
  await useTrustedShopsLibrary()

  // _tsConfig is the name of TrustedShops configuration and can't be changed.
  // eslint-disable-next-line no-underscore-dangle
  window._tsConfig = DEFAULT_WIDGET_CONFIG

  displayBadge.value = true
} catch (error) {
  logger.error(
    typeof error === 'string' ? error : '[TrustedShops] Failed to load script',
  )
}
</script>
