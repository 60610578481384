<template>
  <NextStepsItem :icon="IconMail">
    {{ i18n(translations.step1) }}
    <span class="body-2-bold overflow-wrap-anywhere break-words" data-cs-mask>
      {{ clientEmail }}
    </span>
  </NextStepsItem>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconMail } from '@ds/icons/IconMail'

import NextStepsItem from '../../NextStepsItem/NextStepsItem.vue'

import translations from './OrderDetailsItem.translations'

const i18n = useI18n()

defineProps<{
  clientEmail: string
}>()
</script>
